import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { t, type IntlService } from 'ember-intl';
import { on } from '@ember/modifier';
import { v4 as uuid } from 'uuid';
import { LinkTo } from '@ember/routing';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import Document from 'ember-static-heroicons/components/outline-24/document';
import XCircle from 'ember-static-heroicons/components/outline-24/x-circle';
import type { UploadFile, Queue } from 'ember-file-upload';
import FileUploader from 'tio-ui/components/file-uploader';
import { Button } from 'tio-ui/components/buttons';
import Drawer from 'tio-ui/components/drawer';
import { Select } from 'tio-ui/components/forms';
import CardGeneric from 'tio-ui/components/card-generic';
import { Modal } from 'tio-ui/components/modal';
import { eq, or, not } from 'tio-ui/utilities';
import type FinancialInstitutionModel from 'tio-common/models/financial-institution';
import type PersonModel from 'tio-common/models/person';
import type ObservablesService from 'tio-common/services/observables';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import type ObservabilityService from 'tio-employee/services/observability';
import UploadProgress from 'tio-employee/components/observability/upload-progress';
import type { ObservabilityUploadInstructions, ObservableSource } from 'tio-employee/services/observability';
import { getObservableProductRoute, getRecommendedDocumentType } from 'tio-employee/services/observability';
import { Header, Section, VStack } from 'tio-ui/components/layout';
// wait 10s before telling users to wait for an email
const PROCESSING_TIMEOUT = 10000;
interface ObservabilityUploadRouteSignature {
    Args: {
        model: {
            source?: ObservableSource;
            person: PersonModel;
            financialInstitutions: FinancialInstitutionModel[];
        };
    };
}
let ObservabilityUploadRoute = class ObservabilityUploadRoute extends Component<ObservabilityUploadRouteSignature> {
    @service
    observability: ObservabilityService;
    @service
    observables: ObservablesService;
    @service
    fileQueue: Queue;
    @service
    intl: IntlService;
    @tracked
    batchId?: string;
    @tracked
    instructionsOpen: boolean = false;
    @tracked
    instructionsType?: 'nslds' | 'statement';
    // this can be an incomplete mock financial institution in order to hit the translation
    // select clause default case when the name doesn't match; see servicerOptions getter's
    // return value with mock servicer "General Instructions" spread into it
    @tracked
    financialInstitution?: FinancialInstitutionModel | {
        name: string;
    };
    @tracked
    upload?: UploadFile;
    @tracked
    observableDocument?: ObservableDocumentModel;
    subscription: ReturnType<typeof this.observability.cable.consumer>['subscriptions']['create'] | null = null;
    @tracked
    uploading = false;
    @tracked
    processing = false;
    @tracked
    uploadError?: Error;
    @tracked
    uploadTimeout: ReturnType<typeof setTimeout>;
    @tracked
    timedOut = false;
    constructor(owner1: unknown, args1: ObservabilityUploadRouteSignature['Args']){
        super(owner1, args1);
        this.initializeBatchId();
    }
    willDestroy() {
        clearTimeout(this.uploadTimeout);
        this.subscription.unsubscribe();
    }
    get recommendedType(): 'nslds' | 'statement' {
        return getRecommendedDocumentType(this.args.model.source);
    }
    get optionalType(): 'nslds' | 'statement' {
        return this.recommendedType === 'statement' ? 'nslds' : 'statement';
    }
    get servicerOptions() {
        const servicerName1 = this.intl.t('observability.uploads.placeholder_servicer');
        return [
            {
                name: servicerName1
            },
            ...this.args.model.financialInstitutions
        ];
    }
    @action
    openRecommendedInstructions(): void {
        this.instructionsType = this.recommendedType;
        this.instructionsOpen = true;
    }
    @action
    openOptionalInstructions(): void {
        this.instructionsType = this.optionalType;
        this.instructionsOpen = true;
    }
    @action
    closeInstructions(): void {
        this.instructionsOpen = false;
        this.instructionsType = undefined;
        this.financialInstitution = undefined;
    }
    @action
    setFinancialInstitution(name1: string): void {
        this.financialInstitution = this.args.model.financialInstitutions.find((institution1: FinancialInstitutionModel)=>institution1.name === name1) || {
            name: 'default'
        };
    }
    get instructions(): ObservabilityUploadInstructions {
        if (this.instructionsType === 'nslds') {
            return this.observability.getNsldsInstructions();
        } else {
            return this.observability.getStatementInstructions(this.financialInstitution?.name);
        }
    }
    @action
    initializeBatchId() {
        this.batchId = uuid();
    }
    get queue() {
        // @ts-expect-error "Property 'findOrCreate' does not existon type 'Queue'" - yes it does
        return this.fileQueue.findOrCreate('observable-assets');
    }
    @action
    async handleFileAdded(upload1: UploadFile): Promise<void> {
        this.upload = upload1;
    }
    @action
    removeFile() {
        this.queue.remove(this.upload);
        this.upload = undefined;
    }
    @action
    async handleSubmit(e1: SubmitEvent) {
        e1.preventDefault();
        this.uploading = true;
        this.subscription = this.observability.subscribeToBatchStatus(this.batchId!);
        const person1 = this.args.model.person;
        const batch1 = this.batchId;
        this.observables.createObservableDocument(this.upload!, {
            person: person1,
            batch: batch1
        }).then((document1)=>{
            this.observableDocument = document1;
            this.uploadTimeout = setTimeout(()=>(this.timedOut = true), PROCESSING_TIMEOUT);
            this.processing = true;
            this.uploading = false;
        }).catch((error1)=>{
            this.uploadError = error1;
        });
    }
    @action
    reset() {
        clearTimeout(this.uploadTimeout);
        this.uploading = false;
        this.processing = false;
        this.queue.remove(this.upload);
        this.upload = undefined;
        this.uploadError = undefined;
    }
    static{
        template(`
    <VStack>
      <Header>
        <LinkTo
          @route={{getObservableProductRoute @model.source}}
          class="align-bottom text-gray-900 font-medium"
        >
          <ChevronLeft class="w-5 inline" />
          {{t "back"}}
        </LinkTo>
      </Header>
      <Section>
        <:header>
          {{t "observability.uploads.title"}}
          <p class="text-normal font-normal">
            {{t "observability.uploads.subtitle"}}
          </p>
        </:header>
        <:body>
          <h2 class="tio-h2">
            <span class="block text-fuscia-300 text-sm mb-2">
              {{t "observability.uploads.recommended_prefix"}}
            </span>
            {{#if (eq this.recommendedType "statement")}}
              {{t "observability.uploads.statement.prompt"}}
            {{else}}
              {{t "observability.uploads.nslds.prompt"}}
            {{/if}}
          </h2>
          {{!
            TODO: this seems like a richer button; consider adding CTA component
            to the UI kit - James 20241029
          }}
          <button
            {{on "click" this.openRecommendedInstructions}}
            type="button"
            class="flex w-full items-center sm:w-1/2 outline outline-1 outline-gray-200 bg-gray-50 p-4 hover:bg-gray-100 text-sm text-left text-gray-600 font-light"
          >
            <span class="block mr-4">
              {{#if (eq this.recommendedType "statement")}}
                {{t "observability.uploads.statement.cta"}}
              {{else}}
                {{t "observability.uploads.nslds.cta"}}
              {{/if}}
            </span>
            <ChevronRight class="w-6 !ml-auto flex-shrink-0" />
          </button>
          <hr class="my-6" />
          <h2 class="tio-h2">
            <span class="block text-gray-600 text-sm mb-2">
              {{t "observability.uploads.optional_prefix"}}
            </span>
            {{#if (eq this.recommendedType "statement")}}
              {{t "observability.uploads.nslds.prompt"}}
            {{else}}
              {{t "observability.uploads.statement.prompt"}}
            {{/if}}
          </h2>
          <button
            {{on "click" this.openOptionalInstructions}}
            type="button"
            class="flex w-full items-center sm:w-1/2 outline outline-1 outline-gray-200 bg-gray-50 p-4 hover:bg-gray-100 text-sm text-left text-gray-600 font-light"
          >
            <span class="block mr-4">
              {{#if (eq this.instructionsType "statement")}}
                {{t "observability.uploads.statement.cta"}}
              {{else}}
                {{t "observability.uploads.nslds.cta"}}
              {{/if}}
            </span>
            <ChevronRight class="w-6 !ml-auto flex-shrink-0" />
          </button>
          <form class="mt-16 sm:w-1/2" {{on "submit" this.handleSubmit}}>
            {{#if this.upload}}
              {{! min height to match file uploader }}
              <CardGeneric class="min-h-[11rem]">
                <:header>
                  <h4 class="px-4">{{t "observability.uploads.form.document.selected"}}</h4>
                </:header>
                <:body>
                  <hr />
                  <div class="flex items-center px-4 pt-4 text-tio-gray-600">
                    <Document class="w-6 mr-2" />
                    <span>{{this.upload.file.name}}</span>
                    <button class="ml-auto" type="button" {{on "click" this.removeFile}}>
                      <XCircle class="w-6" />
                    </button>
                  </div>
                </:body>
              </CardGeneric>
            {{else}}
              <h3 class="tio-h3">{{t "observability.uploads.form.document.label"}}</h3>
              <FileUploader
                @name="observable-assets"
                @onFileAdded={{this.handleFileAdded}}
                @useDropzone={{true}}
              >
                <button type="submit">
                  <Document class="w-4 mr-2" />
                  {{t "observability.uploads.form.document.input"}}
                </button>
              </FileUploader>
            {{/if}}
            <Button
              @intent="primary"
              type="submit"
              disabled={{not this.upload}}
              class="mt-4 *:justify-center w-full md:w-1/2"
            >
              {{t "observability.uploads.form.submit"}}
            </Button>
          </form>
        </:body>

      </Section>
    </VStack>
    <Modal @isOpen={{or this.uploading this.processing}} @allowClosing={{false}} @size="xl" as |m|>
      <m.Header>{{t "observability.uploads.progress.header"}}</m.Header>
      <m.Body>
        {{#if this.upload}}
          <UploadProgress
            @observableDocument={{this.observableDocument}}
            @upload={{this.upload}}
            @uploadError={{this.uploadError}}
            @onRetry={{this.reset}}
            @timedOut={{this.timedOut}}
          />
        {{/if}}
      </m.Body>
    </Modal>
    <Drawer @isOpen={{this.instructionsOpen}} @onClose={{this.closeInstructions}} @size="lg">
      <div class="p-4">
        <h2 class="tio-h2 mb-10">{{this.instructions.title}}</h2>
        {{#if (eq this.instructionsType "statement")}}
          <label class="tio-h3">
            <span class="block font-semibold">
              {{t "observability.uploads.financial_institution.label"}}
            </span>
            <span class="block text-sm leading-none">
              {{t "observability.uploads.financial_institution.help"}}
            </span>
            <Select
              @items={{this.servicerOptions}}
              @allowEmpty={{true}}
              @onAction={{this.setFinancialInstitution}}
              @selectionMode="single"
              class="mt-4"
            >
              <:item as |i|>
                <i.Item @key={{i.item.name}}>{{i.item.name}}</i.Item>
              </:item>
            </Select>
          </label>
        {{/if}}
        {{#if (or (eq this.instructionsType "nslds") this.financialInstitution)}}
          {{#if (eq this.instructionsType "statement")}}
            <hr class="my-6" />
          {{/if}}
          <h1>{{this.instructions.header}}</h1>
          {{#if this.instructions.subheader}}
            <h2 class="text-sm">{{this.instructions.subheader}}</h2>
          {{/if}}
          <ol class="my-6 list-decimal">
            {{#each this.instructions.steps as |step|}}
              <li class="ml-10">{{step}}</li>
            {{/each}}
          </ol>
          <p class="my-6">{{this.instructions.footer}}</p>
          <p class="my-6">{{this.instructions.alternateHeader}}</p>
          <ol class="my-6 list-decimal">
            {{#each this.instructions.alternateSteps as |step|}}
              <li class="ml-10">{{step}}</li>
            {{/each}}
          </ol>
        {{/if}}
      </div>
    </Drawer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ObservabilityUploadRoute);
