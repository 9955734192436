import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { courseBadgeState } from 'tio-employee/utils/tas/applicationStateMap';
import { Divider } from 'tio-ui/components/utilities';
import { dropTask } from 'ember-concurrency';
import { formatAsDollars, toLocaleDateString } from 'tio-common/utils/format';
import { getAllValuesForTasField, getSingleValueForTasField, setSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { not } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tasStateLabel, tasStateIntent } from 'tio-common/utils/tuition-assistance/state';
import { tracked } from '@glimmer/tracking';
import { VStack, HStack, Section } from 'tio-ui/components/layout';
import { Select } from 'tio-ui/components/forms';
import { inputsForFields } from 'tio-common/utils/data/inputs-for-fields';
import AcceptConditions from 'tio-employee/components/tuition-assistance/forms/accept-conditions';
import ActionableList from 'tio-ui/components/actionable-list';
import Alert from 'tio-employee/components/tas/applications/alert';
import ApplicationProcess from 'tio-employee/components/tuition-assistance/application/application-process';
import AttachmentList from 'tio-common/components/tuition-assistance/forms/upload-attachments/attachment-list';
import Badge from 'tio-ui/components/badge';
import CheckCircle from 'ember-static-heroicons/components/outline-24/check-circle';
import CheckCircleSolid from 'ember-static-heroicons/components/solid-24/check-circle';
import ClipboardDocumentList from 'ember-static-heroicons/components/outline-24/clipboard-document-list';
import Component from '@glimmer/component';
import CourseSubmitted from 'tio-employee/components/tas/applications/course-submitted';
import DescriptionList from 'tio-ui/components/description-list';
import divide from 'ember-math-helpers/helpers/div';
import NavTabs from 'tio-ui/components/nav-tabs';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import RouteTemplate from 'ember-route-template';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import Table from 'tio-common/components/table/index';
import TasApplicationsShowController from 'tio-employee/controllers/authenticated/tas/applications/show';
import TioButton from 'tio-common/components/tio/button';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import UploadAttachments from 'tio-common/components/tuition-assistance/forms/upload-attachments';
import PencilSquare from 'ember-static-heroicons/components/outline-24/pencil-square';
import TASCourseModel from 'tio-common/models/tas-course';
import type ConditionModel from 'tio-common/models/condition';
import type StoreService from 'tio-common/services/store';
import type RouterService from '@ember/routing/router-service';
import type TasApplicationModel from 'tio-common/models/tas-application';
import type ScholarshipModel from 'tio-common/models/scholarship';
import type { TASCourseModelFieldsSignature } from 'tio-common/models/tas-course';
import type { Intent } from 'tio-ui/utilities';
import TasPageHeader from 'tio-employee/components/tas/page-header';
interface RouteSignature {
    Args: {
        model: TasApplicationModel;
        controller: TasApplicationsShowController;
    };
}
let AuthenticatedTasApplicationsShowComponent = class AuthenticatedTasApplicationsShowComponent extends Component<RouteSignature> {
    @tracked
    acceptedConditions: ConditionModel[] = [];
    @tracked
    submitError = null;
    @tracked
    hasSubmitted = false;
    @tracked
    isGradeModalOpen = false;
    @tracked
    courseToEdit: null | TASCourseModel = null;
    @service
    store: StoreService;
    @service
    router: RouterService;
    get programTemplate() {
        return this.args.model.tasProgramInstance.tasProgramTemplate;
    }
    get applicationApprovalAttachments() {
        return this.args.model.applicationApprovalAssets || [];
    }
    get evidenceAttachments() {
        return this.args.model.evidenceAssets || [];
    }
    get applicationAttachments() {
        if (this.args.model.state === 'TAS.ApplicationState.ATTEND') {
            return this.applicationApprovalAttachments;
        } else {
            return [
                ...this.applicationApprovalAttachments,
                ...this.evidenceAttachments
            ];
        }
    }
    get attachmentsListDescription() {
        return (getAllValuesForTasField('COMPLETION_DOCUMENTS_ATTACHMENT_FIELD', this.programTemplate.fields).join(', ') || 'None Required');
    }
    get coursesApprovalAttachments() {
        return (getAllValuesForTasField('COURSE_APPROVAL_DOCUMENTS_ATTACHMENT_FIELD', this.programTemplate.fields).join(', ') || 'None Required');
    }
    get requireAttachmentUploads() {
        if (this.programTemplate.isCertificateProgram) {
            return (this.programTemplate.requireAttachmentsForCourseCompletion || this.programTemplate.isEvidenceRequired);
        }
        return this.programTemplate.isEvidenceRequired;
    }
    get conditionsForSubmit() {
        return this.programTemplate.applicationApproveEvidenceConditions || [];
    }
    get showSubmitForEvidenceReview() {
        return this.args.model.state === 'TAS.ApplicationState.ATTEND';
    }
    submitForEvidenceApproval = dropTask(async ()=>{
        try {
            this.submitError = null;
            this.hasSubmitted = true;
            await this.store.adapterFor('tas-application').requestCourseEvidence(this.args.model);
            const tasType1 = this.programTemplate.typeClassification === 'TAS.ProgramType.3' ? 'three' : 'four';
            this.router.transitionTo('authenticated.tas.confirmation', {
                queryParams: {
                    tasType: tasType1,
                    applicationId: this.args.model.id,
                    evidence: 'true'
                }
            });
        } catch (error1) {
            console.error('Failed to submit application:', error1);
            this.submitError = error1;
        }
    });
    get hasAgreedAllConditions() {
        if (!this.conditionsForSubmit.length) {
            return true;
        }
        return this.conditionsForSubmit.length === this.acceptedConditions.length;
    }
    get hasRequiredAttachments() {
        return this.args.model.hasRequiredEvidence;
    }
    get canSubmitApplication() {
        const { isCourseGradeRequired: isCourseGradeRequired1, isMissingCourseGrades: isMissingCourseGrades1, state: state1 } = this.args.model;
        const sharedConditions1 = this.hasAgreedAllConditions && this.hasRequiredAttachments && state1 === 'TAS.ApplicationState.ATTEND';
        return isCourseGradeRequired1 ? sharedConditions1 && !isMissingCourseGrades1 : sharedConditions1;
    }
    get expenseType(): string {
        const expenseType1 = getSingleValueForTasField('EXPENSE_TYPE', this.programTemplate.fields);
        return expenseType1 ? `${expenseType1}` : `Education`;
    }
    get scholarships() {
        return getAllValuesForTasField('SCHOLARSHIPS_RECEIVED' as keyof TASCourseModelFieldsSignature, this.args.model.fields as unknown as TASCourseModelFieldsSignature) as unknown as ScholarshipModel[];
    }
    get courseBadge() {
        return courseBadgeState(this.args.model.state) as Record<string, Intent>;
    }
    get showInstructions() {
        const typeClassificationsToHide1 = [
            'TAS.ProgramType.3',
            'TAS.ProgramType.4'
        ];
        return (typeClassificationsToHide1.includes(this.programTemplate.typeClassification) && this.args.model.state === 'TAS.ApplicationState.ATTEND');
    }
    get grades() {
        return inputsForFields['COURSE_GRADE'].options.map((option1)=>option1.value);
    }
    get selectedGrade() {
        // @selectedKeys is an array
        const grade1 = getSingleValueForTasField('COURSE_GRADE', this.courseToEdit!.fields) as string;
        return grade1 ? [
            grade1
        ] : [];
    }
    showAddResult = (course1: TASCourseModel)=>{
        const addResultStates1 = [
            'TAS.ApplicationState.ATTEND',
            'TAS.ApplicationState.PENDING_EVIDENCE_APPROVAL',
            'TAS.ApplicationState.EVIDENCE_NOT_APPROVED',
            'TAS.ApplicationState.EVIDENCE_REJECTED'
        ];
        return (addResultStates1.includes(course1.tasApplication.state) && (this.programTemplate.typeClassification === 'TAS.ProgramType.3' || this.programTemplate.typeClassification === 'TAS.ProgramType.4'));
    };
    showCompletedGrade = (course1: TASCourseModel)=>{
        const completedStates1 = [
            'TAS.ApplicationState.EVIDENCE_APPROVED',
            'TAS.ApplicationState.FULFILLED',
            'TAS.ApplicationState.ABANDONED'
        ];
        return (completedStates1.includes(course1.tasApplication.state) && (this.programTemplate.typeClassification === 'TAS.ProgramType.3' || this.programTemplate.typeClassification === 'TAS.ProgramType.4'));
    };
    courseInProgress = (course1: TASCourseModel)=>{
        const inProgressStates1 = [
            'TAS.ApplicationState.CREATED',
            'TAS.ApplicationState.DEFINE_COURSES',
            'TAS.ApplicationState.PENDING_COURSES_APPROVAL',
            'TAS.ApplicationState.COURSES_APPROVED',
            'TAS.ApplicationState.COURSES_NOT_APPROVED',
            'TAS.ApplicationState.COURSES_REJECTED'
        ];
        return (inProgressStates1.includes(course1.tasApplication.state) && (this.programTemplate.typeClassification === 'TAS.ProgramType.3' || this.programTemplate.typeClassification === 'TAS.ProgramType.4'));
    };
    @action
    closeApplicationSubmittedModal() {
        this.args.controller.applicationSubmitted = false;
    }
    @action
    didUpdateAcceptedConditions(conditions1: ConditionModel[] = []) {
        this.acceptedConditions = conditions1;
    }
    @action
    openGradeModal(course1: TASCourseModel) {
        this.isGradeModalOpen = true;
        this.courseToEdit = course1;
    }
    @action
    closeGradeModal() {
        this.isGradeModalOpen = false;
        this.courseToEdit = null;
    }
    @action
    onSelectionChange(key1: string[]) {
        setSingleValueForTasField('COURSE_GRADE', key1[0]!, this.courseToEdit!.fields);
        this.courseToEdit!.save();
    }
    static{
        template(`
    <VStack>
      <TasPageHeader>
        <:title as |title|>
          <title.BackLink @route="authenticated.tuition-assistance.dashboard">
            {{t "tas.default"}}
          </title.BackLink>
        </:title>
      </TasPageHeader>
      <NavTabs @model={{@model}} class="mb-4" as |navtabs|>
        <navtabs.item @route="authenticated.tas.applications.show" @model={{@model}}>
          {{t "tas.applications.default"}}
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.applications.history" @model={{@model}}>
          {{t "tas.history.default"}}
        </navtabs.item>
      </NavTabs>
      <HStack>
        <VStack class="lg:!w-2/3 md:!w-1/2">
          <Section>
            <:header>{{@model.applicationName}}</:header>
            <:body>
              <Alert @application={{@model}} />
              <h2 class="font-medium text-tio-gray-700 my-4">
                {{@model.tasProgramInstance.programName}}
              </h2>
              {{! APPLICANT TABLE }}
              <Table @isLoading={{false}}>
                <:thead as |options|>
                  <options.tr>
                    <options.th>
                      {{t "tas.applications.applicant"}}
                    </options.th>
                    <options.th>{{t "tas.applications.amount"}}</options.th>
                    <options.th>{{t "tas.applications.submission_date"}}</options.th>
                    <options.th>{{t "tas.applications.status"}}</options.th>
                  </options.tr>
                </:thead>
                <:tbody as |options|>
                  <options.tr>
                    <options.td>{{@model.applicant}}</options.td>
                    <options.td>{{formatAsDollars @model.requestedTotal}}</options.td>
                    <options.td>{{toLocaleDateString @model.courseSubmittedDate}}</options.td>
                    <options.td>
                      {{#if @model.inRepayment}}
                        <Badge @intent={{tasStateIntent @model.paymentState}}>
                          {{tasStateLabel @model.paymentState}}
                        </Badge>
                      {{else}}
                        <Badge @intent={{tasStateIntent @model.state @model.fromState}}>
                          {{tasStateLabel @model.state @model.fromState}}
                        </Badge>
                      {{/if}}
                    </options.td>
                  </options.tr>
                </:tbody>
              </Table>
            </:body>
          </Section>
          <Section>
            <:header>{{t "tas.applications.details"}}</:header>
            <:body>
              {{! APPLICATION DETAILS }}
              <DescriptionList class="sm:w-2/3" as |List|>
                <List.Group>
                  <List.Term>{{t "tas.applications.application_name"}}</List.Term>
                  <List.Details>{{@model.applicationName}}</List.Details>
                </List.Group>
                <List.Group>
                  <List.Term>{{t "tas.applications.start_date"}}</List.Term>
                  <List.Details>{{toLocaleDateString @model.coursesBeginDate}}</List.Details>
                </List.Group>
              </DescriptionList>
            </:body>
          </Section>
          {{! COURSE DETAILS - EXPENSES }}
          <Section>
            <:header>{{this.expenseType}}</:header>
            <:body>
              <ActionableList @striped={{true}} as |l|>
                {{#each @model.activeCourses as |course|}}
                  <l.Row class="w-full">
                    <l.Term class="w-96">{{course.courseName}}</l.Term>
                    <l.Item class="w-96">
                      {{safeFormatNumber
                        (divide course.courseTotal 100)
                        style="currency"
                        currency="USD"
                      }}
                    </l.Item>
                    {{#if (this.courseInProgress course)}}
                      <l.Item
                        class="ml-0 mr-2 flex items-center font-semibold text-ocean-600 cursor-pointer md:ml-auto"
                      >
                        <Badge @intent={{this.courseBadge.intent}}>
                          {{this.courseBadge.label}}
                        </Badge>
                      </l.Item>
                    {{else if (this.showAddResult course)}}
                      <l.Item
                        class="ml-0 mr-2 flex items-center font-semibold text-ocean-600 cursor-pointer md:ml-auto"
                        {{on "click" (fn this.openGradeModal course)}}
                      >
                        {{#if course.courseGrade}}
                          {{course.courseGrade}}
                          <PencilSquare class="ml-2 h-6 w-6 text-tio-gray-700" />
                        {{else}}
                          {{t "tas.benefits_summary.add_result_simple"}}
                        {{/if}}
                      </l.Item>
                    {{else if (this.showCompletedGrade course)}}
                      <l.Item
                        class="ml-0 mr-2 flex items-center font-semibold text-ocean-600 md:ml-auto"
                      >
                        {{course.courseGrade}}
                      </l.Item>
                    {{/if}}
                  </l.Row>
                {{/each}}
              </ActionableList>
            </:body>
          </Section>
          {{#if this.scholarships}}
            <Section>
              <:header>
                {{t "tas.new.scholarships.default"}}
              </:header>
              <:body>
                {{! SCHOLARSHIPS }}
                <ActionableList @striped={{true}} as |l|>
                  {{#each this.scholarships as |scholarship|}}
                    <l.Row class="w-full">
                      <l.Term class="w-96">{{scholarship.scholarshipName}}</l.Term>
                      <l.Item class="w-96">
                        {{safeFormatNumber
                          (divide scholarship.scholarshipAmount 100)
                          style="currency"
                          currency="USD"
                        }}
                      </l.Item>
                    </l.Row>
                  {{/each}}
                </ActionableList>
              </:body>
            </Section>
          {{/if}}

          {{! ATTACHMENTS }}
          <Section>
            <:header>
              <div>{{t "attachments"}}</div>
              <div class="font-normal">
                {{t "tas.application.type_two.new.attachment_description"}}
                {{#if this.requireAttachmentUploads}}
                  <span class="text-error-400 text-xl">*</span>
                {{/if}}
              </div>
            </:header>
            <:body>
              <UploadAttachments
                @programTemplate={{this.programTemplate}}
                @classification="COMPLETION_DOCUMENTS"
                @attachable={{@model}}
                @attachableType="tasApplication"
                @useDropzone={{true}}
                @hideInstructions={{not this.showInstructions}}
                @hideAttachments={{false}}
                @disabled={{false}}
                {{!-- @disabled={{this.disableAttachmentUpload}} --}}
              />
              <div class="flex items-center w-full">
                <AttachmentList
                  @assets={{this.applicationAttachments}}
                  @disabled={{true}}
                  class="w-full"
                />
              </div>
            </:body>
          </Section>
        </VStack>
        {{! Evidence Submission and Payment Details }}
        <VStack class="lg:!w-1/3 md:!w-1/2">
          {{! Submit For Evidence Review }}
          {{#if this.showSubmitForEvidenceReview}}
            <Section
              class="w-full"
              @headerClass="bg-tio-gray-100 flex text-sm items-center gap-2 px-5 py-2 font-medium tracking-wide"
            >
              <:header>
                <ClipboardDocumentList class="h-8 w-8" />
                <p>{{t "tas.application.submit_evidence_for_review"}}</p>
              </:header>
              <:body>
                <section class="mb-5">
                  <p class="text-sm">
                    <span class="text-error-400 ml-1">
                      *
                    </span>
                    {{t "tas.benefits_summary.required_items"}}
                  </p>
                  <div>
                    <div class="flex flex-row justify-between items-center">
                      <p class="font-medium">{{t "tas.benefits_summary.attachments"}}
                        <span class="text-error-400 ml-1">
                          {{#if this.requireAttachmentUploads}}*{{/if}}
                        </span>
                      </p>

                      <div>
                        <UploadAttachments
                          @programTemplate={{this.programTemplate}}
                          @classification="COMPLETION_DOCUMENTS"
                          @attachable={{@model}}
                          @attachableType="tasApplication"
                          @useDropzone={{false}}
                          @iconOnlyUpload={{true}}
                          @hideAttachments={{true}}
                          @hideInstructions={{true}}
                        />
                      </div>
                    </div>

                    <div class="flex items-center gap-3">
                      {{#if @model.isMissingRequiredEvidence}}
                        <CheckCircle class="h-6 w-6 text-tio-gray-200" />
                      {{else}}
                        <CheckCircleSolid class="h-6 w-6 text-violet-500" />
                      {{/if}}
                      <p>{{this.attachmentsListDescription}}</p>
                    </div>
                  </div>
                  <Divider class="mt-4" />

                  {{#if this.conditionsForSubmit.length}}
                    <AcceptConditions
                      @conditions={{this.conditionsForSubmit}}
                      @onChange={{this.didUpdateAcceptedConditions}}
                    />
                  {{/if}}
                </section>

                <div class="py-4">
                  <Button
                    class="flex justify-center w-full py-3 px-6 me-2 mb-2 font-medium text-lg text-white focus:outline-none bg-ocean-600 rounded-lg hover:bg-ocean-400"
                    disabled={{not this.canSubmitApplication}}
                    @isRunning={{this.submitForEvidenceApproval.isRunning}}
                    {{on "click" this.submitForEvidenceApproval.perform}}
                  >
                    {{t "tas.benefits_summary.submit_evidence"}}
                  </Button>
                  <p class="text-sm font-light text-center">
                    {{t "tas.benefits_summary.helper_text"}}
                  </p>
                  {{#if this.submitError}}
                    <TioErrorMessages
                      @showErrors={{this.hasSubmitted}}
                      @error={{this.submitError}}
                      class="text-sm"
                      @icon="warning"
                    />
                  {{/if}}
                </div>
              </:body>
            </Section>
          {{/if}}

          <PaymentDetailsCard
            @isPaid={{@model.isPaid}}
            @requestedTotal={{@model.requestedTotal}}
            @payments={{@model.paymentsHistory}}
          />

          <ApplicationProcess @application={{@model}} @programTemplate={{this.programTemplate}} />
        </VStack>
      </HStack>
    </VStack>

    <Modal
      @isOpen={{@controller.applicationSubmitted}}
      @onClose={{this.closeApplicationSubmittedModal}}
      class="min-h-64 !max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      <m.Header class="text-midnight font-semibold">
        {{t "tuition_assistance.submitted.application_submitted"}}</m.Header>
      <m.Body>
        <CourseSubmitted @application={{@model}} @courseApprovalRequired={{true}} />
        <div class="mb-4 flex justify-center">
          <TioButton
            @onClick={{this.closeApplicationSubmittedModal}}
            @outlined={{true}}
            class="w-48"
          >
            {{t "common.close"}}
          </TioButton>
        </div>
      </m.Body>
    </Modal>
    <Modal
      @isOpen={{this.isGradeModalOpen}}
      @onClose={{this.closeGradeModal}}
      class="!max-w-screen-sm"
      @backdrop="faded"
      as |m|
    >
      <m.Header>
        <h1 class="text-midnight font-semibold">{{t "tas.benefits_summary.add_result"}}</h1>
      </m.Header>
      <m.Body>
        <div class="flex justify-between mb-6">
          <h2 class="text-xl font-semibold text-gray-700">
            {{! @glint-expect-error}}
            {{getSingleValueForTasField "COURSE_NAME" this.courseToEdit.fields}}
          </h2>
          <div class="w-72">
            <Select
              @items={{this.grades}}
              @selectedKeys={{this.selectedGrade}}
              @onSelectionChange={{this.onSelectionChange}}
            >
              <:item as |o|>
                <o.Item @key={{o.item}} @intent="default" @appearance="faded">
                  {{o.item}}
                </o.Item>
              </:item>
            </Select>
          </div>
        </div>
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onCancel={{this.closeGradeModal}}
          @onSubmit={{this.closeGradeModal}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AuthenticatedTasApplicationsShowComponent);
